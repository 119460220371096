<template>
    <v-container fluid style="margin: 0px; padding: 0;">
            <line-c
            :data="data"
            :options="options"
            />
    </v-container>
</template>

<script>
import {
    Chart as ChartJS, registerables
} from 'chart.js'
import { Line as LineC } from 'vue-chartjs'
import annotationPlugin from 'chartjs-plugin-annotation';

ChartJS.register(
    ...registerables,
    annotationPlugin,
)

export default {
    name: 'LineChart',

    components: {
        LineC
    },

    props: {
        customTooltipCallbacks: null,
        data: null,
        title: null,
        scales: null,
        lineAnnotation: null,
    },

    methods: {
    },

    data: () => ({
        chartOptions: {
            borderColor: '#648140',
            color: '#90EE90',
            elements: {
                line: {
                    tension: 0.5
                },
            },
            interaction: {
                intersect: false,
                mode: 'index'
            },
            plugins: {
                tooltip: {
                    backgroundColor: '#E2E6AF',
                    bodyColor: '#000000',
                    titleColor: '#000000',
                    displayColors: false,
                    footerColor: 'red',
                    footerFont: {size: 10},
                },
                legend: {
                    display: false,
                },
            },
        },
    }),

    computed: {
        options() {
            this.chartOptions.plugins.tooltip.callbacks = this.customTooltipCallbacks
            this.chartOptions.plugins.title = this.title
            this.chartOptions.scales = this.scales
            this.chartOptions.plugins.annotation = {
                    annotations: {
                        line1: this.lineAnnotation,
                    }
                }
            return this.chartOptions
        }
    },
}
</script>