import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/Index'),
      children: [
        // Dashboard
        {
          name: '索引',
          path: 'index',
          component: () => import('@/views/Dashboard'),
        },
        {
          name: '独轮车',
          path: 'unicycle',
          component: () => import('@/views/Unicycle'),
        },
        {
          name: '关于',
          path: 'about',
          component: () => import('@/views/About'),
        },
        {
          name: '主播信息',
          path: 'streamer/:uid',
          component: () => import('@/views/Streamer'),
        },
        {
          name: '直播信息',
          path: 'streamer/:uid/stream/:sid',
          component: () => import('@/views/Stream'),
        },
        {
          name: '总览',
          path: 'streamer/:uid/overview',
          component: () => import('@/views/Overview'),
        },
        {
          name: '斗虫',
          path: 'compare',
          component: () => import('@/views/Compare'),
        },
        {
          name: 'Solo',
          path: 'compare/solo',
          component: () => import('@/views/Solo'),
        },
        {
          name: '大乱斗',
          path: 'compare/arena',
          component: () => import('@/views/Arena'),
        },
        {
          name: '团战',
          path: 'compare/teamfight',
          component: () => import('@/views/Teamfight'),
        },
        {
          name: '超时空斗虫',
          path: 'compare/chronoarena',
          component: () => import('@/views/ChronoArena'),
        },
        {
          name: '使用指北',
          path: 'handbook',
          component: () => import('@/views/Handbook'),
        },
        //{
        //  name: '营收数据',
        //  path: 'income',
        //  component: () => import('@/views/Income'),
        //},
        {
          name: 'A Letter From Me',
          path: 'letter',
          component: () => import('@/views/Letter'),
        },
        {
          name: 'Not Found',
          path: '*',
          redirect: '/index',
        },
      ],
    },
  ],
})
