<template>
    <v-container :style="'padding: 0;'">
        <v-row :style="'margin: auto; margin-top: 5px; margin-bottom: 5px;'">
            <v-col cols="12" md="3" lg="2">
                <router-link v-if="uid" :to="'/streamer/' + uid + '/stream/' + stream.id" :style="'color: black; text-decoration: none;'" @click.native="$emit('click')">
                    <v-lazy>
                        <img :src="stream.cover" :referrerpolicy="'no-referrer'" style="width: 100%;" />
                    </v-lazy>
                </router-link>
                <span v-else>
                    <v-lazy>
                        <img :src="stream.cover" :referrerpolicy="'no-referrer'" style="width: 100%;" />
                    </v-lazy>
                </span>
            </v-col>
            <v-col>
                <v-row align-content="center">
                    <v-col :style="'padding-left: 0; padding-top: 0'">
                        <v-card-title :style="'padding-left: 0; align-content: center;'">
                            <router-link v-if="uid" :to="'/streamer/' + uid + '/stream/' + stream.id" :style="'color: black; text-decoration: none;'" @click.native="$emit('click')">
                                {{ stream.title }}
                            </router-link>
                            <span v-else>
                                {{ stream.title }}
                            </span>
                            <v-tooltip v-if="!stream.isFull" top style="z-index: 1025;">
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" color="red">
                                        mdi-alert-circle-outline
                                    </v-icon>
                                </template>
                                <span>数据不全</span>
                            </v-tooltip>
                        </v-card-title>
                    </v-col>
                </v-row>
                <v-row :style="'margin-top: 0px;'">
                    <v-col :style="'padding-left: 0;'">
                        <v-row>
                            <v-card-subtitle style="padding-bottom: 0;">开始时间</v-card-subtitle>
                        </v-row>
                        <v-row>
                            <v-card-subtitle style="padding-top: 0;">
                                {{ (new Date(this.stream.startTime)).toLocaleString('zh-CN', {dateStyle: 'long', timeStyle: 'medium'}) }}
                            </v-card-subtitle>
                        </v-row>
                    </v-col>
                    <v-col>
                        <v-row>
                            <v-card-subtitle style="padding-bottom: 0;">直播时长</v-card-subtitle>
                        </v-row>
                        <v-row>
                            <v-card-subtitle style="padding-top: 0;">{{
                                stream.isFinish
                                    ? Math.abs((stream.endTime - stream.startTime) / 1000 / 60 / 60).toFixed(1) + '小时'
                                    : '未结束'
                            }}</v-card-subtitle>
                        </v-row>
                    </v-col>
                    <v-col>
                        <v-row>
                            <v-card-subtitle style="padding-bottom: 0;">弹幕数量</v-card-subtitle>
                        </v-row>
                        <v-row>
                            <v-card-subtitle style="padding-top: 0;">{{ stream.danmakuCount }}</v-card-subtitle>
                        </v-row>
                    </v-col>
                    <v-col>
                        <v-row>
                            <v-card-subtitle style="padding-bottom: 0;">营收</v-card-subtitle>
                        </v-row>
                        <v-row>
                            <v-card-subtitle style="padding-top: 0;">{{ stream.income }}元</v-card-subtitle>
                        </v-row>
                    </v-col>
                    <v-col>
                        <v-row>
                            <v-card-subtitle style="padding-bottom: 0;">最高高能</v-card-subtitle>
                        </v-row>
                        <v-row>
                            <v-card-subtitle style="padding-top: 0;">{{ stream.maxOnline }}</v-card-subtitle>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-divider />
    </v-container>
</template>

<script>
export default {
    name: 'StreamCard',

    props: {
        stream: {
            type: Object,
            default: null
        },
        uid: null,
    },

    computed: {
    },
}
</script>

<style lang="sass">
  .v-application .pa-3
    &
      padding: 7px !important
  .v-card--material
    &*
      padding: 0px
    &__avatar
      position: relative
      top: -64px
      margin-bottom: -32px

    &__heading
      position: relative
      top: -40px
      transition: .3s ease
      z-index: 1
    
  .v-card-material .v-card__title
    &
      padding-top: 3px
      padding-bottom: 3px
</style>
