<template>
  <v-card
    v-bind="$attrs"
    :class="classes"
    class="v-card--material pa-3"
    hover
    :to="'streamer/' + streamer.uid"
  >
    <div class="d-flex grow flex-wrap">
      <v-row no-gutters align="center">
        <v-col cols="3">
            <v-lazy>
              <v-avatar>
                <img :src="streamer.avatar" alt="" referrerpolicy="no-referrer" />
              </v-avatar>
            </v-lazy>
        </v-col>
        <v-col cols="9">
          <v-card-title>{{ streamer.uname }}</v-card-title>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
  export default {
    name: 'StreamerCard',

  props: {
    streamer: {
      type: Object,
      default: null
    }
  },

    computed: {
      classes () {
        return {
          'v-card--material--has-heading': this.hasHeading,
        }
      },
      hasHeading () {
        return Boolean(this.$slots.heading || this.title || this.icon)
      },
      hasAltHeading () {
        return Boolean(this.$slots.heading || (this.title && this.icon))
      },
    },
  }
</script>

<style lang="sass">
  .v-application .pa-3
    &
      padding: 7px !important
  .v-card--material
    &*
      padding: 0px
    &__avatar
      position: relative
      top: -64px
      margin-bottom: -32px

    &__heading
      position: relative
      top: -40px
      transition: .3s ease
      z-index: 1
    
  .v-card-material .v-card__title
    &
      padding-top: 3px
      padding-bottom: 3px
</style>
