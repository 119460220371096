<template>
    <v-container fluid style="margin: 0px; padding: 0;">
        <pie :data="data" :options="options" />
    </v-container>
</template>

<script>
import {
    Chart as ChartJS, ArcElement, Tooltip, Legend
} from 'chart.js'
import { Pie } from 'vue-chartjs'

ChartJS.register(
    ArcElement, Tooltip, Legend
)

export default {
    name: 'PieChart',

    components: {
        Pie
    },

    props: {
        customTooltipCallbacks: null,
        data: null,
        title: null,
    },

    methods: {
    },

    data: () => ({
        chartOptions: {
            plugins: {
                tooltip: {
                    backgroundColor: '#E2E6AF',
                    bodyColor: '#000000',
                    titleColor: '#000000',
                    displayColors: false,
                },
                legend: {
                    position: 'bottom',
                }
            },
        },
    }),

    created() {
    },

    computed: {
        options() {
            this.chartOptions.plugins.tooltip.callbacks = this.customTooltipCallbacks
            this.chartOptions.plugins.title = this.title
            return this.chartOptions
        }
    },

    watch: {
    }
}
</script>