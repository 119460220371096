<template>
  <router-view />
</template>

<script>
  export default {
    name: 'App',
    metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: '索引',
      // all titles will be injected into this template
      titleTemplate: '%s | Good afternoon, good evening and good night'
    }
  }
</script>
